import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Form, InputGroup } from 'react-bootstrap';
import withAuth from '../components/withAuth';
import { makeAuthenticatedRequest } from './utils';

function AdminPage() {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const fetchOrganizations = async () => {
    try {
      const response = await makeAuthenticatedRequest('/api/admin/organizations');
      if (response.ok) {
        const data = await response.json();
        setOrganizations(data.organizations); // Update this line
      } else {
        throw new Error('Failed to fetch organizations');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleIncreaseCredits = async (orgId, amount) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/admin/organizations/${orgId}/increase_credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount }),
      });

      if (response.ok) {
        // Refresh the organizations list
        await fetchOrganizations();
      } else {
        throw new Error('Failed to increase credits');
      }
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Container className="mt-4">
      <h1>Admin Dashboard</h1>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Organization ID</th>
            <th>Name</th>
            <th>Owner</th>
            <th>Current Credits</th>
            <th>Users</th>
            <th>Workspaces</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {organizations.map((org) => (
            <tr key={org.id}>
              <td>{org.id}</td>
              <td>{org.name}</td>
              <td>{org.owner}</td>
              <td>{org.credits}</td>
              <td>{org.users.join(', ')}</td>
              <td>{org.workspaces.join(', ')}</td>
              <td>
                <InputGroup>
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    aria-label="Credit amount"
                    min="1"
                    defaultValue="100"
                  />
                  <Button 
                    variant="primary" 
                    onClick={(e) => handleIncreaseCredits(org.id, e.target.previousSibling.value)}
                  >
                    Increase Credits
                  </Button>
                </InputGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default withAuth(AdminPage);
