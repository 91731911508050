import { createRef, useEffect, useMemo, useState } from 'react';
import { Accordion, Container, Row, Col, Card, Spinner, Tabs, Tab, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';


import {CustomPlaceholder, ParagraphPlaceholder} from '../routes/issue_details_components';
import {Attachment, AttachmentModal, Breadcrumb, CommentsCard} from '../routes/issue_details_components';
import {createDisplayStepFromLogAnalysisData, createDisplayStepFromMetricsAnalysisData} from '../routes/utils';

import MetricView from './metrics';
import LogsView from './logs';

import {IoThumbsDownOutline, IoThumbsUpOutline, IoThumbsDown, IoThumbsUp} from 'react-icons/io5';

import './demo.css';

import {getLoginInfoFromCookie, makeAuthenticatedRequest} from '../routes/utils';

const ReadableRootCause = ({ rootCause, referenceClickHandler }) => {
  let sections = rootCause.sections || [];
  const onClick = (ref) => {
    referenceClickHandler(ref);
  }

  return (
    <div>
      {sections.map((section, index) => {
        if (typeof section.text !== 'string') return null;
        return (
          <p className="mb-3" key={index}>
            <span>{section.text}</span>
            {section.references && Array.isArray(section.references) && section.references.map((ref, refIndex) => {
              if (typeof ref !== 'number') return null;
              return (
                <span key={refIndex} className="ms-2">
                  [<button className="btn btn-link m-0 p-0" onClick={() => onClick(ref)}>
                    {ref}
                  </button>]
                </span>
              );
            })}
          </p>
        );
      })}
    </div>
  );
}

const DetailsTab = ({ issue, onShowModal }) => (
  <>
    <DescriptionCard details={issue.details} />
    <AttachmentsCard 
      relevantFiles={issue.relevant_files}
      services={issue.system?.services}
      relevantFileData={issue.relevant_file_data}
      onShowModal={onShowModal}
    />
    <CommentsCard />
  </>
);

const AnalysisTab = ({ issue, analysisData, onRunAnalysis, onUserPrompt, onInterrupt, selectIssueFromIdHandler }) => (
  true ? (
    <div>
      <IssueDataView issue={issue} />
      <AnalysisDataView issue={issue} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={selectIssueFromIdHandler} />
    </div>
  ) : (
    <div className="text-center py-4">
      <Spinner animation="border" role="status" variant="purple">
        <span className="visually-hidden">Loading analysis...</span>
      </Spinner>
    </div>
  )
);

const DescriptionCard = ({ details }) => (
  <Card className="mb-3 description-card">
    <Card.Body>
      <Card.Title>Description</Card.Title>
      <Card.Text>{details || <ParagraphPlaceholder />}</Card.Text>
    </Card.Body>
  </Card>
);

const AttachmentsCard = ({ relevantFiles, services, onShowModal }) => {
  const relevantDataByServices = () => {
    if (!relevantFiles || !services) {
      return [];
    }

    let relevantData = new Map();
    services.forEach((service) => {
      relevantFiles.forEach((fileName) => {
        if (fileName.startsWith(service.name)) {
          if (!relevantData.has(service.name)) {
            relevantData.set(service.name, []);
          }
          relevantData.get(service.name).push(fileName);
        }
      });
    });
    return Array.from(relevantData, ([service, files]) => [service, files]);
  };

  return (
    <Card className="mb-3 attachments-card">
      <Card.Body>
        <Card.Title className="d-flex justify-content-between align-items-center mb-3">
          See logs and metrics
        </Card.Title>
        {!relevantFiles ? (
          <div className="text-center py-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          relevantDataByServices().map(([service, files]) => (
            <div key={service} className="mb-3">
              <h6>Service Name: {service}</h6>
              <div className="d-flex flex-wrap gap-3">
                {files.map((fileName) => (
                  <Attachment
                    key={fileName}
                    fileName={fileName}
                    onClick={() => onShowModal(fileName)}
                  />
                ))}
              </div>
            </div>
          ))
        )}
      </Card.Body>
    </Card>
  );
};


const LogAnalysisStepView = (props) => {
  let stepTitle = `Analyzing logs from service '${props.analysisStepData['file_analyzed']}'`;
  let stepTitleComponents = {
    'action': 'logs',
    'service': props.analysisStepData['file_analyzed'],
    'stepIndx': props.stepIndx,
  };
  return (
		<LogsView 
			logData={props.analysisStepData.observation.logLines} 
			highlight={props.analysisStepData.observation.highlight} 
			stepTitleComponents={stepTitleComponents}
			stepReason={props.analysisStepData.reason} 
			query={props.analysisStepData.query} 
			stepPostscript={props.analysisStepData.observation.relevance} 
		/>
  );
};

const MetricAnalysisStepView = (props) => {
  let stepTitle = `Analyzing metric '${props.analysisStepData['metric_analyzed']}' from service '${props.analysisStepData['service_analyzed']}'`;
  let metricData = props.analysisStepData.metric ? [props.analysisStepData.metric] : [];
  let stepTitleComponents = {
    'action': 'metrics',
    'metric': props.analysisStepData['metric_analyzed'],
    'service': props.analysisStepData['service_analyzed'],
    'stepIndx': props.stepIndx,
  };

  return (
		<MetricView 
			metricData={metricData} 
			stepTitleComponents={stepTitleComponents}
			stepReason={props.analysisStepData.reason} 
			stepPostscript={props.analysisStepData.summary} 
			query={props.analysisStepData.query} 
		/>
  );
};

const SimilarIssues = (props) => {

  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Previous Similar Issues</Card.Title>
        <Card.Text>
          <ul class="text-truncate">
          {props.analysisData.relatedIssueData.map((issue) => (
            <li key={issue.id}>
              <button type="button" class="btn btn-link" onClick={() => props.selectIssueFromIdHandler(issue.id)}>{issue.summary}</button>
            </li>
            ))}
          </ul>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const RequestingInfo = (props) => {
  const [idValues, setIdValues] = useState([]);

  useEffect(() => {
    setIdValues(props.analysisData.identifiersToAskFor.map(() => ''));
  }, []);

  const handleInputChange = (index) => (event) => {
    const newIdValues = [...idValues];
    newIdValues[index] = event.target.value;
    setIdValues(newIdValues);
  }


  const onSubmit = () => {
    var userPrompt = props.analysisData.identifiersToAskFor[0] + ": " + idValues[0];
    for (var indx=1; indx < idValues.length; indx++) {
      if (idValues[indx].trim().length === 0) {
        continue;
      }
      userPrompt += ", " + props.analysisData.identifiersToAskFor[indx] + ": " + idValues[indx];
    }
    props.onUserPrompt(userPrompt);
  }


  return (
    <Card className="mb-3">
      <Card.Body>
        <Card.Title>Requesting Information</Card.Title>
        <Card.Text>
          <div class="row">
            <div class="col-md-12">
              <p>We think the following information would be helpful to diagnose the issue. Please provide if you can:</p>
            </div>
          </div>
            {props.analysisData.identifiersToAskFor.map((identifier, index) => (
              <div class="row my-2">
                <div class="col-md-3">
                  {identifier}
                </div>
                <div class="col-md-9">
                  <input type="text" class="form-control"
                  onChange={handleInputChange(index)}
                  value={idValues[index]} />
                </div>
              </div>
            ))}
              <div class="row my-3">
                <div class="col-md-2">
                  <button 
                    type="submit" 
                    className="btn btn-primary custom-btn m-0"
                    style={{
                      backgroundColor: '#5935c9',
                      borderColor: '#5935c9',
                      color: 'white',
                      transition: 'background-color 0.3s, color 0.3s'
                    }}
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
        </Card.Text>
      </Card.Body>
    </Card>
    );
};


const AnalysisSteps = (props) => {
  const getStepTitle = (step, index) => {
    if (step['step_type'] === 'ongoing') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>
          <span className="highlight-ongoing">Currently running</span>: {step['currentNextStep']}
        </>
      );
    } else if (step['step_type'] === 'user_input') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>
          <span className="highlight-logs">User Suggestion</span>
          <code className="service-name">{step['message']}</code>
        </>
      );
    } else if (step['step_type'] === 'log_analysis') {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzing
          {' '}
          <span className="highlight-logs">logs</span>
          {' '}
          from service
          {' '}
          <code className="service-name" style={{ maxWidth: '150px' }}>{step['file_analyzed']}</code>
        </>
      );
    } else {
      return (
        <>
          <span className="step-number">Step {index + 1}</span>Analyzing
          {' '}
          <span className="highlight-logs">metric</span>
          {' '}
          <code className="service-name" style={{ maxWidth: '400px' }}>{step['metric_analyzed']}</code>
          {' '}
          from service
          {' '}
          <code className="service-name" style={{ maxWidth: '150px' }}>{step['service_analyzed']}</code>
        </>
      );
    }
  };

  const scrollRef = useMemo(() => createRef(), []);

  useEffect(() => {
    if (props.analysisData.stillRunning && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Card className="my-3 analysis-steps">
      <Card.Body>
        <Card.Title>Step-by-Step Root Cause Analysis</Card.Title>
        {props.analysisData && props.analysisData.analysisSteps.length > 0 && (
        <Accordion alwaysOpen>
          {props.analysisData.analysisSteps.map((step, index) => (
            <Accordion.Item key={index} eventKey={index.toString()} id={'step-' + (index+1)}>
              <Accordion.Header>
              {getStepTitle(step, index)}
                {/* Step {index + 1}: {step['step_type'] === 'log_analysis' ? 'Log Analysis' : 'Metric Analysis'} */}
              </Accordion.Header>
              <Accordion.Body>
                {step['step_type'] === 'log_analysis' && <LogAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
                {step['step_type'] === 'metrics_analysis' && <MetricAnalysisStepView stepIndx={index + 1} analysisStepData={step} />}
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        ) 
        }
      </Card.Body>
      <div ref={scrollRef} />
    </Card>
  );
};

const AnalysisContent = ({ analysisData, scrollToStepIndx }) => {
  const isDataAvailable = (analysisData && analysisData.rootCause) || (analysisData && analysisData.possibleRootCauseSoFar);
  if (!isDataAvailable) {
    return (
      <>
        <Card.Title>Analysis in Progress</Card.Title>
        <Card.Text>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Card.Text>
      </>
    );
  }

  return (
    <>
      {analysisData.rootCause && (
        <>
          <Card.Title>Possible Root Cause</Card.Title>
          {analysisData.readableRootCause ? (
            <ReadableRootCause rootCause={analysisData.readableRootCause} referenceClickHandler={scrollToStepIndx} />
          ) : (
            <Card.Text>{analysisData.rootCause}</Card.Text>
          )}
          {analysisData.nextStep && analysisData.nextStep !== "root_cause" && (
            <div className="mt-3">
              <Card.Title>Recommended Action</Card.Title>
              <p>{analysisData.nextStep}</p>
              <p>{analysisData.nextStepReason}</p>
            </div>
          )}
        </>
      )}
      {!analysisData.rootCause && analysisData.possibleRootCauseSoFar && (
        <>
          <Card.Title>Analysis Summary, so far</Card.Title>
          {analysisData.readableRootCauseSoFar ? (
            <ReadableRootCause rootCause={analysisData.readableRootCauseSoFar} referenceClickHandler={scrollToStepIndx} />
          ) : (
            <Card.Text>{analysisData.possibleRootCauseSoFar}</Card.Text>
          )}
        </>
      )}
    </>
  );
};

const FeedbackButtons = ({ feedbackState, handleFeedback }) => {
  const feedbackOptions = [
    { tooltip: "Good response", icon: IoThumbsUp, outlineIcon: IoThumbsUpOutline, value: 1 },
    { tooltip: "Bad response", icon: IoThumbsDown, outlineIcon: IoThumbsDownOutline, value: -1 },
  ];

  return (
    <div className="mt-3 d-flex justify-content-end">
      {feedbackOptions.map(({ tooltip, icon: Icon, outlineIcon: OutlineIcon, value }) => (
        <OverlayTrigger
          key={value}
          placement="top"
          overlay={<Tooltip id={`tooltip-${value}`}>{tooltip}</Tooltip>}
        >
          <Button
            variant="outline-dark"
            size="sm"
            className={value === 1 ? "me-1" : ""}
            onClick={() => handleFeedback(value)}
          >
            {feedbackState === value ? <Icon /> : <OutlineIcon />}
          </Button>
        </OverlayTrigger>
      ))}
    </div>
  );
};

const AnalysisDataView = (props) => {
  const isDataAvailable = (props.analysisData && props.analysisData.rootCause) || (props.analysisData && props.analysisData.possibleRootCauseSoFar);
  const [userHint, setUserHint] = useState('');
  const [isInterrupting, setIsInterrupting] = useState(false);
  const [feedbackState, setFeedbackState] = useState(null);

  useEffect(() => {
    if (props.analysisData && props.analysisData.state === 'INTERRUPTING') {
      setIsInterrupting(true);
    } else {
      setIsInterrupting(false);
    }

  }, [props.analysisData]);

  useEffect(() => {
    if (props.analysisData?.feedbackScore === 1) {
      setFeedbackState(1);
    } else if (props.analysisData?.feedbackScore === -1) {
      setFeedbackState(-1);
    } else {
      setFeedbackState(null);
    }
  }, [props.analysisData?.feedbackScore]);

  const handleInterrupt = () => {
    setIsInterrupting(true);
    props.onInterrupt().then(() => {
      //setIsInterrupting(false);
    }).catch(() => {
      setIsInterrupting(false);
    });
  };

  const [isPrompting, setIsPrompting] = useState(false);
  const handlePrompt = () => {
    setIsPrompting(true);
    const result = props.onUserPrompt(userHint);
    Promise.resolve(result)
      .then(() => {
        setIsPrompting(false);
        setUserHint("");
      })
      .catch((error) => {
        console.error("Error in onUserPrompt:", error);
        setIsPrompting(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handlePrompt();
    }
  };

  const scrollToStepIndx = (stepIndx) => {
    const element = document.getElementById(`step-${stepIndx}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const handleFeedback = async (score) => {
    try {
      const response = await makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.issue.id}/feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback_score: score }),
      });

      if (response.ok) {
        setFeedbackState(score);
      } else {
        console.error("Failed to submit feedback");
        console.log(response);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };



  if (!props.analysisData) {
    return (
      <div>
        <Button
            variant="success"
            type="submit"
            className="align-self-end" onClick={props.onRunAnalysis}>Run Analysis</Button>
      </div>
      );
  }

  return (<div>
    <Card className="mb-3">
      <Card.Body>

        <AnalysisContent analysisData={props.analysisData} scrollToStepIndx={scrollToStepIndx} />

        {!props.analysisData.stillRunning && (
          <FeedbackButtons
            feedbackState={feedbackState}
            handleFeedback={handleFeedback}
          />
        )}
        </Card.Body>
    </Card>

    {props.analysisData && props.analysisData.relatedIssueData && props.analysisData.relatedIssueData.length > 0 && (<SimilarIssues analysisData={props.analysisData} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />)}
    {props.analysisData && props.analysisData.identifiersToAskFor && props.analysisData.identifiersToAskFor.length > 0 && (
      <>
      <RequestingInfo analysisData={props.analysisData} onUserPrompt={props.onUserPrompt} />
    </>)}

    <AnalysisSteps analysisData={props.analysisData} />

    {/* Spinner for ongoing analysis */}
    {props.analysisData.stillRunning && (<div className="d-flex justify-content-between align-items-center mt-3">
      <div className="flex-grow-1">
        {props.analysisData.state === 'IN_PROGRESS' && (
          <div className="d-flex align-items-center">
            <Spinner animation="border" role="status" size="sm">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <span style={{ color: '#5935c9', marginLeft: '8px' }} className="ms-2">Relvy working its magic....</span>
          </div>
        )}
      </div>
      <button 
        className="btn btn-outline-danger ms-3" 
        onClick={handleInterrupt}
        disabled={isInterrupting}
      >
        {isInterrupting ? (
          <>
            <Spinner animation="border" size="sm" className="me-2" />
            Stopping...
          </>
        ) : (
          'Stop Analysis'
        )}
      </button>
    </div>)}

    {/* Prompt for user input */}
    {true && (
      <div class="card my-3">
        <div class="row mx-3 my-3">
          <div class="col-md-11">
            <input 
              type="text" 
              placeholder="Ask Relvy to look at other logs or metrics..." 
              className="form-control" 
              id="alsoLookAt" 
              value={userHint} 
              onChange={(e) => setUserHint(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
					<div class="col-md-1">
            <button 
              type="submit" 
              className="btn btn-primary custom-btn m-0"
              style={{
                backgroundColor: '#5935c9',
                borderColor: '#5935c9',
                color: 'white',
                transition: 'background-color 0.3s, color 0.3s'
              }}
              onClick={handlePrompt}
              disabled={isPrompting}
            >
              Submit
            </button>
          </div>

        </div>
      </div>
    )}
  </div>);
};

const IssueDataView = (props) => {
  return (<div>
        <Card className="mb-3">
          <Card.Body>
            <Card.Title>Description</Card.Title>
            <Card.Text>{props.issue.summary || '...'}</Card.Text>
            {props.issue.description && (<div dangerouslySetInnerHTML={{ __html: props.issue.description }} />)}
          </Card.Body>
        </Card>
      </div>);
}

const MainContent = ({ issue, analysisData, onRunAnalysis, onUserPrompt, onInterrupt, selectIssueFromIdHandler }) => {
  return (
    <div>
      <AnalysisTab issue={issue} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={selectIssueFromIdHandler} />
    </div>
  );
};


const IssueDetailsPage = (props) => {
  const [issueData, setIssueData] = useState({
    summary: props.item.summary,
    details: props.item.description,
    relevant_files: null,
  });
  const [analysisData, setAnalysisData] = useState(null);

  const [failureMessage, setFailureMessage] = useState(null);
  const [pollerId, setPollerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [numPollRequests, setNumPollRequests] = useState(0);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const processIssueData = (data) => {
    setIssueData((prevData) => ({
      ...prevData,
      ...data,
    }));

    processAnalysisData(data['analysis_results']);
  };

  const fetchIssueData = async (issueId) => {
    
    if (numPollRequests > 100) {
      return;
    }
    setNumPollRequests(numPollRequests + 1);
    const url = `/api/dashboard/v2/issues/${issueId}`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let response = await makeAuthenticatedRequest(url, options);
    if (response.ok) {
      processIssueData(await response.json());
    } else {
      setFailureMessage("Failed to fetch data");
    }
  };

  const clearPollerIfExists = () => {
    clearInterval(pollerId);
  };

  useEffect(() => {
    clearPollerIfExists();
    setNumPollRequests(0);
    fetchIssueData(props.item.id);
    let newPollerId = setInterval(() => {
      fetchIssueData(props.item.id);
    }, 2000);
    setPollerId(newPollerId);

    return () => {
      clearInterval(newPollerId);
    }
  }, [props.item.id]);


  const getReadableNextStep = (nextStep, params) => {
    if (nextStep === 'request_logs') {
      return "Request logs from " + params["service"];
    } else if (nextStep === 'request_metrics') {
      return "Request metrics from " + params["service"] + ' - ' + params["metric_name"];
    } else if (nextStep === 'escalate') {
      return "Escalate to " + params['to'];
    } else {
      return nextStep;
    }
  }

  useEffect(() => {
    if (!analysisData){
      clearPollerIfExists();
      return;
    }

    if (analysisData && !analysisData.stillRunning) {
      clearPollerIfExists();
      props.refreshCallback(false);
    }
  }, [analysisData]);


  const processAnalysisData = (data) => {
    if (!data){
      setAnalysisData(null);
      return;
    }

    var output = {
      'identifiersToAskFor': data['identifiers_to_ask_for'],
      'relatedIssueData': data['related_issue_data'],
    };
    
    let finalResponse = data["final_response"];
    output['stillRunning'] = (data["state"] === "IN_PROGRESS" || data["state"] === "INTERRUPTING" || data["state"] === "NOT_STARTED");
    output['state'] = data["state"];
    if (finalResponse && "next_step" in finalResponse) {
      output['nextStep'] = getReadableNextStep(finalResponse["next_step"], finalResponse["params"]);
      output['nextStepReason'] = finalResponse["reason"];
      output['rootCause'] = finalResponse["possible_root_cause_so_far"];
      output['readableRootCause'] = finalResponse["readable_rca"];
      //output['stillRunning'] = false;
    }

    output['possibleRootCauseSoFar'] = null;

    var displayAnalysisSteps = [];
    var displayNextStep = "";
    var displayNextStepReason = "";
    for (var stepIndx=0; stepIndx < data["analysis_history"].length; stepIndx++) {
      let analysisStepType = data["analysis_history"][stepIndx].step_type;
      let analysisStepData = data["analysis_history"][stepIndx].step_data;
      //console.log(analysisStepType);
      if (analysisStepType === "next_step") {
        output['possibleRootCauseSoFar'] = analysisStepData["possible_root_cause_so_far"];
        output['readableRootCauseSoFar'] = analysisStepData["readable_rca"];
        if (analysisStepData["next_step"] === "request_logs") {
          displayNextStep = "Process logs from " + analysisStepData["params"]["service"];
        } else if (analysisStepData["next_step"] === "request_metrics") {
          displayNextStep = "Process metrics from " + analysisStepData["params"]["service"] + ' - ' + analysisStepData["params"]["metric_name"];
        } else {
          displayNextStep = analysisStepData["next_step"];
        }
        displayNextStepReason = analysisStepData["reason"];
      } else if (analysisStepType === "observation"){
        if (analysisStepData["observation_type"] === 'log_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromLogAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else if (analysisStepData["observation_type"] === 'metrics_analysis'){
          displayAnalysisSteps.push(createDisplayStepFromMetricsAnalysisData(
            analysisStepData, displayNextStep, displayNextStepReason));
        } else {
          console.log("Unknown observation type: " + analysisStepData["observation_type"]);
        }
      } else if (analysisStepType === "user_input") {
        displayAnalysisSteps.push({
          'step_type': 'user_input',
          'message': analysisStepData['message'],
        });
      }
    }

    if (output['stillRunning'] && data["analysis_history"].length > 0 && data['analysis_history'][data["analysis_history"].length - 1].step_type === 'next_step') {
      displayAnalysisSteps.push({
        'step_type': 'ongoing',
        'currentNextStep': displayNextStep,
        'currentNextStepReason': displayNextStepReason,
      });
    }

    output['analysisSteps'] = displayAnalysisSteps;
    output['feedbackScore'] = data['feedback_score'];
    setAnalysisData(output);
  };

  const onInterrupt = () => {
    return makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.item.id}/interrupt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        return Promise.resolve();
      } else {
        console.log("Failed to interrupt analysis");
        return Promise.reject("Failed to interrupt analysis");
      }
    });
  };

  const onRunAnalysis = () => {
    return makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.item.id}/analyze`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        // Restart the polling mechanism
        setNumPollRequests(0);
        let newPollerId = setInterval(() => {
          fetchIssueData(props.item.id);
        }, 2000);
        setPollerId(newPollerId);
      } else {
        console.error("Failed to run analysis");
        return Promise.reject("Failed to run analysis");
      }
    });
  };

  const onUserPrompt = (message) => {
    // clearPollerIfExists();

    let updatedAnalysisData = {...analysisData};
    updatedAnalysisData.analysisSteps.push({
      'step_type': 'user_input',
      'message': message,
    });
    setAnalysisData(updatedAnalysisData);

    return makeAuthenticatedRequest(`/api/dashboard/v2/issues/${props.item.id}/prompt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: message,
      }),
    }).then((response) => {
      if (response.ok) {
        fetchIssueData(props.item.id);
        // Restart the polling mechanism
        setNumPollRequests(0);
        let newPollerId = setInterval(() => {
          fetchIssueData(props.item.id);
        }, 2000);
        setPollerId(newPollerId);
      } else {
        console.log("Failed to submit message");
        throw new Error("Failed to submit message");
      }
    });
  };

  return (
    <MainContent issue={issueData} analysisData={analysisData} onRunAnalysis={onRunAnalysis} onUserPrompt={onUserPrompt} onInterrupt={onInterrupt} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />
  );
};

export default IssueDetailsPage;
