import * as Sentry from "@sentry/react";

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Button } from 'react-bootstrap';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
// import './index.css';
import reportWebVitals from './reportWebVitals';

import DashboardV2 from './routes/dashboard_v2';
import ExpertSteps from './routes/expert_steps';

// import Dashboard from './routes/dashboard';
// import Sandbox from './routes/sandbox'
// import IssueViewer from './routes/issue_viewer';
// import IssueAnalysisViewer from './routes/issue_analysis_viewer';
// import DatasetViewer from './routes/dataset_viewer';
// import CreateIncident from './routes/create_incident';
// import IssueDetailsPage from './routes/issue_details';
// import IssuesList from './routes/issue_list';

import Configuration from './routes/configuration';
import Landing from './routes/landing';
import Setup from './routes/setup';
import Settings from './routes/settings';
import Invitation from './routes/invitation';
import AdminPage from './routes/admin';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './components/demo.css';

import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { IoSettings } from 'react-icons/io5';  // Add this import

import { logout, getIdTokenFromCookie } from './routes/utils';  // Add this import

Sentry.init({
  dsn: "https://27f1492c3e2306cf911c611a516f242f@o4508031859097600.ingest.us.sentry.io/4508031861063680",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.relvy\.ai\//],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


function NavbarComponent() {
  const handleLogout = (event) => {
    event.preventDefault();
    logout();
  };

  return (
    <Navbar expand="lg" fixed="top" className="shadow-sm" bg="white">
      <Container>
        <Navbar.Brand href="/" rel="noopener noreferrer" className="fw-bold">
          <img alt="Relvy" src="/RelvyAI_FullLogo_Cropped.png" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav className="ms-auto">
            <Button href="https://share.hsforms.com/1R29gPNRURmKe6vLR_0Q9vQrhaij" target="_blank" rel="noopener noreferrer" className="me-lg-3 outline-custom-btn">
              Get in Touch
            </Button>
            {getIdTokenFromCookie() && (
              <NavDropdown title={<IoSettings size={28} color="#5935c9" />} id="user-dropdown" align="end">
                <NavDropdown.Item href="/account">Account</NavDropdown.Item>
                <NavDropdown.Item href="https://www.relvy.ai/relvy-tos" target="_blank" rel="noopener noreferrer">Terms and Policies</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout}>Log Out</NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/dashboard",
    element: <DashboardV2 />,
  },
  // {
  //   path: "/dashboard",
  //   element: <Dashboard />,
  // },
  // {
  //   path: "/issue_list",
  //   element: <IssuesList />,
  // },
  {
    path: "/issues/:issueId/expert_steps",
    element: <ExpertSteps />,
  },
  // {
  //   path: "/sandbox/create",
  //   element: <CreateIncident />,
  // },
  // {
  //   path: "/admin/dataset_viewer",
  //   element: <DatasetViewer />,
  // },
  // {
  //   path: "/admin/issue_viewer",
  //   element: <IssueViewer />,
  // },
  // {
  //   path: "/admin/issue_analysis_viewer",
  //   element: <IssueAnalysisViewer />,
  // },
  // {
  //   path: "/issue_details",
  //   element: <IssueDetailsPage />,
  // },
  {
    path: "/configure",
    element: <Configuration />,
  },
  {
    path: "/setup",
    element: <Setup />,
  },
  {
    path: "/account",
    element: <Settings />,
  },
  {
    path: "/relvyadmin",
    element: <AdminPage />,
  },
  {
    path: "/invitation",
    element: <Invitation />,
  },
]);

function RelvyApp() {
  return (
    <>
      <NavbarComponent />
      <section style={{ paddingTop: '4rem' }}>
        <RouterProvider router={router} />
      </section>
    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="415844705945-j6o5aomfu4jmqgg86d2ehg4p4905u5ia.apps.googleusercontent.com">
      <RelvyApp />
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
