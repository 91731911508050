import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { getLoginInfoFromCookie, makeAuthenticatedRequest } from './utils';

const AcceptInvite = () => {
  const [invitationInfo, setInvitationInfo] = useState(null);
  const [loginState, setLoginState] = useState(null);
  const [displayMessage, setDisplayMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [accepting, setAccepting] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  // Extract token from query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    if (!token) {
      setErrorMessage('No invitation token provided.');
      return;
    }

    // Fetch invitation info
    fetch(`${API_BASE_URL}/api/invitation?token=${token}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage('There is some error, please contact us.');
        } else {
          setInvitationInfo(data);
          setDisplayMessage(`You have been invited to join ${data.organization.name}. Please sign in with Google to continue.`);
        }
      })
      .catch(() => setErrorMessage('Failed to fetch invitation info.'));

    // Check login state
    getLoginInfoFromCookie(
      (data) => setLoginState(data),
      () => setLoginState(null)
    );
  }, [token, API_BASE_URL]);

  const handleLoginSuccess = (credentialResponse) => {
    document.cookie = 'gloginInfo=' + JSON.stringify(credentialResponse);
    fetch(`${API_BASE_URL}/api/google_signin`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id_token: credentialResponse.credential }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorMessage('Google sign-in failed.');
        } else {
          setLoginState(data);
          setDisplayMessage(`Join ${invitationInfo.organization.name} as ${data.user.email}`);
        }
      })
      .catch(() => setErrorMessage('Google sign-in failed.'));
  };

  const acceptInvitation = () => {
    setAccepting(true);
    makeAuthenticatedRequest(`/api/accept_invitation`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token }),
    })
      .then((response) => response.json())
      .then((data) => {
        setAccepting(false);
        if (data.error) {
          setErrorMessage('There is some error, please contact us.');
        } else {
          // Redirect to dashboard or appropriate page
          window.location.href = '/dashboard';
        }
      })
      .catch(() => {
        setAccepting(false);
        setErrorMessage('Failed to accept invitation.');
      });
  };

  if (errorMessage) {
    return (
      <div className="container text-center">
        <div className="alert alert-danger mt-5">{errorMessage}</div>
      </div>
    );
  }

  if (!invitationInfo) {
    return (
      <div className="container text-center mt-5">
        <p>Loading invitation details...</p>
      </div>
    );
  }

  return (
    <div className="container d-flex flex-column" style={{ minHeight: '90vh' }}>
      <div className="row flex-grow-1 align-items-center justify-content-center">
        <div className="col-md-4 col-sm-10 text-center">
          <img
            src="/RelvyAI_JustLogo.png"
            alt="Relvy Logo"
            className="img-fluid mb-4"
            style={{ maxWidth: '100px' }}
          />
          <h3 className="mb-3">Welcome to Relvy</h3>
          <div className="card p-3 shadow-sm mb-4">
            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}
            <p className="mb-3" style={{ fontSize: '1rem' }}>
              {displayMessage}
            </p>
            <div className="d-flex justify-content-center align-items-center">
              {!loginState ? (
                <GoogleLogin
                  onSuccess={handleLoginSuccess}
                  onError={() => {
                    setErrorMessage('Google sign-in failed.');
                  }}
                  render={({ onClick, disabled }) => (
                    <button
                      className="btn btn-light btn-md w-100 d-flex align-items-center justify-content-center"
                      onClick={onClick}
                      disabled={disabled}
                      style={{ minHeight: '40px' }}
                    >
                      <img
                        src="/google-logo.png"
                        alt="Google logo"
                        className="me-2"
                        style={{ width: '20px', height: '20px' }}
                      />
                      Sign in with Google
                    </button>
                  )}
                />
              ) : (
                <button
                  className="btn custom-btn btn-md w-100"
                  onClick={acceptInvitation}
                  disabled={accepting}
                >
                  {accepting ? 'Accepting...' : 'Accept Invitation'}
                </button>
              )}
            </div>
          </div>
          <hr className="w-100 mx-auto mb-3" />
          <div className="explore-section">
            <h5 className="mb-3">Explore Relvy</h5>
            <div className="d-grid gap-2 col-8 mx-auto">
              <a
                href="https://share.hsforms.com/1R29gPNRURmKe6vLR_0Q9vQrhaij"
                className="btn btn-sm btn-outline-primary"
                target="_blank"
                rel="noreferrer"
              >
                Self-host Relvy (Enterprise)
              </a>
              <a
                href="https://sandbox.relvy.ai"
                className="btn btn-sm btn-outline-secondary"
                target="_blank"
                rel="noreferrer"
              >
                Try Sandbox Demo
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-auto py-3">
        <div className="text-center">
          <small>
            <a
              href="https://www.relvy.ai/privacy-policy"
              className="text-muted me-2"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            |
            <a
              href="https://www.relvy.ai/relvy-tos"
              className="text-muted ms-2"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </small>
        </div>
      </footer>
    </div>
  );
};

export default AcceptInvite;
